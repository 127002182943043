/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XButton from 'vux/src/components/x-button';
import { JSEncrypt } from "jsencrypt";
export default {
  name: 'Login',
  components: {
    XButton: XButton
  },
  data: function data() {
    return {
      contentWidth: 112,
      contentHeight: 38,
      identifyCodeinfo: '',
      identifyCode: '1s9c',
      //后端返回的验证码
      toast: '',
      //toast内容
      comActive: false,
      //账号输入状态1
      companyAc: require('@/assets/company_active.png'),
      companyNo: require('@/assets/company_no.png'),
      accActive: false,
      //账号输入状态1
      accountAc: require('@/assets/account_active.png'),
      accountNo: require('@/assets/account_no.png'),
      identify: require('@/assets/shenpi/授权.png'),
      identifyNo: require('@/assets/shenpi/验证码.png'),
      psdActive: false,
      //密码输入状态
      identifyCodeaccActive: false,
      passwordAc: require('@/assets/password_active.png'),
      passwordNo: require('@/assets/password_no.png'),
      psdOpen: false,
      //是否显示密码
      passwordOpen: require('@/assets/password_open.png'),
      passwordClose: require('@/assets/password_close.png'),
      radioData: [{
        checked: false,
        title: '个人'
      }, {
        checked: false,
        title: '商户'
      }],
      companyId: '',
      account: '',
      //账号
      password: '',
      isPsd: true,
      //密码输入格式是否正确
      timer: null,
      otherType: '',
      ipcon: '',
      port: '',
      showhide: false,
      loginType: '0',
      //默认选中
      title: '欢迎使用微信平台',
      key: '',
      //获取密钥
      keyja: "",
      //密码加密key
      randomStr: '',
      codeImg: '',
      //验证码照片
      yanz: 0 //是否开启验证 0关闭  1开启

    };
  },
  methods: {
    // 获取是否打开
    getyanzhen: function getyanzhen() {
      var _this2 = this;

      this.$api.Login.getyanzhen().then(function (response) {
        var code = response.code,
            data = response.data;

        if (code == 600) {
          _this2.yanz = data;

          if (_this2.yanz == 1) {
            _this2.getverification();
          }
        } else {
          _this2.yanz = 0;
        }
      });
    },
    // 当工号输入后去获取密钥key
    getKey: function getKey() {
      if (this.yanz == 0) {
        return;
      }

      var _this = this;

      this.$api.Login.getKey({
        userNo: this.account
      }).then(function (response) {
        var code = response.code,
            data = response.data;

        if (code == 600) {
          _this.keyja = data.publicKey;
          _this.randomStr = data.randomStr;

          _this.selectLogin();
        }
      });
    },
    // 做一个异步处理在显示验证码的地方请求接口
    getverification: function getverification() {
      var _this3 = this;

      this.$api.Login.getverifications({
        width: this.contentWidth + '',
        height: this.contentHeight + ''
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (code == 600) {
          _this3.codeImg = data.images;
          _this3.key = data.key;
        } else {
          _this3.$utils.Tools.toastShow(msg);
        }
      });
    },
    // 添加一个接口判断是否是第一次登录 或 解除绑定后再次登录
    selectLogin: function selectLogin() {
      var _this4 = this;

      this.companyId = this.companyId.trim().substring(this.companyId.trim().length - 8, this.companyId.trim().length); // 登录按钮点击量
      // document.body && (document.body.scrollTop = document.body.scrollTop);
      // 密码加密

      var passwords = '';

      if (this.yanz == 1) {
        var str = this.password + this.randomStr;
        var encrytor = new JSEncrypt();
        encrytor.setPublicKey(this.keyja);
        passwords = encodeURIComponent(encrytor.encrypt(str));
      } else {
        passwords = this.password;
      }

      this.$utils.Tools.loadShow();
      this.$api.Login.selectlogin({
        userNo: this.account,
        userPassword: passwords,
        openId: this.$utils.Store.getItem('openId'),
        //?this.$utils.Store.getItem('openid'):null//openid，登录需要
        companyId: this.companyId,
        loginType: this.loginType,
        // 登录类型，商户和普通用户
        key: this.key,
        //登录密钥
        verification: this.identifyCodeinfo //验证码

      }).then(function (response) {
        var msg = response.msg;

        if (msg == '此账号未绑定') {
          _this4.$utils.Store.setItem('firstLogin', 1);
        } else {
          _this4.$utils.Store.setItem('firstLogin', 0);
        }

        _this4.login(_this4.account, passwords, _this4.companyId, _this4.loginType, _this4.key, _this4.identifyCodeinfo);
      });
    },
    login: function login(userNo, userPassword, companyId, loginType, key, verification) {
      var _this5 = this;

      if (this.isbtnDis === false) {
        this.$api.Login.login({
          userNo: userNo,
          userPassword: userPassword,
          openId: this.$utils.Store.getItem('openId'),
          //?this.$utils.Store.getItem('openid'):null//openid，登录需要
          companyId: companyId,
          loginType: loginType,
          // 登录类型，商户和普通用户
          key: key,
          //登录密钥
          verification: verification //验证码

        }).then(function (response) {
          _this5.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg,
              data = response.data;

          if (+code !== _this5.$code.success) {
            _this5.$utils.Tools.toastShow(msg);
          } else {
            _this5.updateInfo(data);
          }
        }).catch(function () {
          _this5.$utils.Tools.httpError();
        });
      }
    },
    getHashParameters: function getHashParameters() {
      var arr = (window.location.href || '').split('?')[1].split('&');
      var params = {};

      for (var i = 0; i < arr.length; i++) {
        var data = arr[i].split('=');

        if (data.length === 2) {
          params[data[0]] = data[1];
        }
      }

      return params;
    },
    click1: function click1() {//选择商户或个人登录，改变预设loginType的值，0为个人，1为商户
      // alert($("input[name='identity']:checked").val());
      // this.loginType = $('input[name=\'identity\']:checked').val();
    },
    move: function move() {
      var _this6 = this;

      //输入法键盘冒起时上移输入框使其不被键盘覆盖
      setTimeout(function () {
        _this6.$refs.loginDiv.scrollTop = 170;
      }, 700);
    },
    psdInput: function psdInput(password) {
      //密码格式验证
      var str = password;

      if (str == null || str.length < 6 || str.length > 16) {
        this.isPsd = false;
        return false;
      }

      var reg1 = new RegExp(/^[0-9A-Za-z]+$/);

      if (!reg1.test(str)) {
        this.isPsd = false;
        return false;
      }

      var reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);

      if (reg.test(str)) {
        this.isPsd = true;
        return true;
      } else {
        this.isPsd = false;
        return false; // this.$utils.Store.getItem('UserSerial')
      }
    },
    choose: function choose(item) {
      //传参
      if (item.title === '个人') {
        this.loginType = 0;
      } else if (item.title === '商户') {
        this.loginType = 1;
      }
    },
    updateInfo: function updateInfo(data) {
      var _this7 = this;

      var user = data;
      this.$utils.Store.setLocalItem('account', this.account);
      this.$utils.Store.setLocalItem('companyId', this.companyId);
      this.$utils.Store.setItem('companyId', this.companyId);
      this.$utils.Custom.setUserInfo(data);

      if (this.$params.mode === '1') {
        this.$api.Login.updateInfo({
          openId: data.openId,
          companyId: this.companyId,
          unionId: data.unionId,
          userSerial: data.userSerial,
          userNo: data.userNo,
          userLname: data.userLname,
          orgId: data.orgId,
          orgName: data.orgName
        }).then(function (response) {
          var code = response.code,
              msg = response.msg;

          if (+code !== _this7.$code.success) {
            _this7.$utils.Tools.toastShow(msg);
          } else {
            _this7.goPage(user);
          }
        }).catch(function () {
          _this7.$utils.Tools.httpError();
        });
      } else {
        this.goPage(user);
      }
    },
    goPage: function goPage(data) {
      var page = this.$route.params.page;

      if (page !== undefined) {
        this.$router.replace({
          name: page,
          params: {
            state: this.$route.params.state
          }
        });
      } else {
        this.$router.replace({
          path: '/',
          query: {
            loginType: '1',
            safePwd: data.safePwd
          }
        });
      }
    },
    accFocus: function accFocus() {
      //输入账号获取焦点时
      this.accActive = true;
    },
    accBlur: function accBlur() {
      //输入账号失去焦点时
      // document.body && (document.body.scrollTop = document.body.scrollTop);
      this.accActive = false;
    },
    accBlurcode: function accBlurcode() {// 验证码输入框 失去焦点  触发验证码核对
    },
    comFocus: function comFocus() {
      this.comActive = true;
    },
    comBlur: function comBlur() {
      // document.body && (document.body.scrollTop = document.body.scrollTop);
      this.comActive = false;
    },
    psdFocus: function psdFocus() {
      //输入密码获取焦点时
      this.psdActive = true;
    },
    accFocusidentifyCode: function accFocusidentifyCode() {
      this.identifyCodeaccActive = true;
    },
    psdBlur: function psdBlur() {
      // document.body && (document.body.scrollTop = document.body.scrollTop);
      //输入密码失去焦点时
      this.psdActive = false;
    },
    acidentifyCodeinfo: function acidentifyCodeinfo() {
      this.identifyCodeaccActive = false;
    },
    openPsd: function openPsd() {
      //是否显示密码
      this.psdOpen = !this.psdOpen;
      this.$refs.pwdRef.focus();
    },
    hideToast: function hideToast() {
      this.isToast = false;
    },
    showToast: function showToast() {
      this.isToast = true;
    }
  },
  computed: {
    isbtnDis: function isbtnDis() {
      return !(this.account !== '' && this.password !== '');
    }
  },
  destroyed: function destroyed() {
    clearTimeout(this.timer);
  },
  created: function created() {
    var _this8 = this;

    this.$api.Sys.getSysSetting().then(function (res) {
      _this8.title = res.title;
    });
    this.$utils.Store.removeItem('init');
    var temp = this.$utils.Store.getLocalItem('account');
    this.account = temp === null ? '' : temp;
    temp = this.$utils.Store.getLocalItem('companyId');
    this.companyId = temp === null ? '' : temp;
    this.getyanzhen(); //获取是否打开验证码
  }
};